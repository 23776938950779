.copy-button {
  appearance: none;
  background-color: var(--color-bg-secondary);
  border: 1px solid var(--color-border-primary);
  border-radius: var(--border-radius-lg);
  color: var(--color-fg-secondary);
  cursor: pointer;
  font-size: var(--font-size-sm);
  opacity: 0;
  padding: 3px 6px;
  position: absolute;
  right: var(--spacing-sm);
  top: var(--spacing-sm);
  transition: opacity 0.1s ease-in-out;

  &:hover {
    color: var(--color-fg-primary);
  }

  &::before {
    content: "Copy";
  }

  // This class will be toggled via JavaScript
  &.copied {
    pointer-events: none;

    &::before {
      content: "Copied";
    }
  }

  .hack:hover & {
    opacity: 1;
    transition-delay: 0.2s;
  }
}
