.hack {
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
}

.hack__heading {
  align-items: baseline;
	display: flex;
	justify-content: flex-start;
}

.hack__client {
	font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.hack__anchor {
  color: var(--color-fg-secondary);
  margin-left: var(--spacing-sm);
  opacity: 0;

  .hack:hover & {
    opacity: 1;
    transition-delay: 0.2s;
  }
}

.hack__main {
  margin-top: var(--spacing-md);
  // Needed for positioning copy button
  position: relative;

  pre {
    background-color: var(--color-bg-secondary);
    border-radius: var(--border-radius-md);
    margin: 0;
    white-space: pre-wrap;
  }

  pre > code {
    display: block;
    font-size: var(--font-size-sm);
    font-variant-ligatures: none; // Stops #ffff from getting fancied up
    line-height: var(--line-height-lg);
    padding: var(--spacing-md);
    width: 100%;
  }

  p {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-lg);
    margin-top: var(--spacing-md);

    code {
      background-color: var(--color-bg-secondary);
      border-radius: var(--border-radius-sm);
      font-size: var(--font-size-xs);
      padding: 2px 4px;
    }
  }

  p + p {
    margin-top: var(--spacing-sm);
  }

  .hack__meta {
    color: var(--color-fg-secondary);
    font-size: var(--font-size-xs);
  }
}

.hack__contributor,
.hack__contributor-name {
  color: inherit;
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  transition: var(--transition-all-short);

  &:hover {
    text-decoration: underline;
  }
}

.hacks:not(.hacks--show-deprecated) .hack--deprecated {
  display: none;
}