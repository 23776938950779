html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-sans-serif);
}

a {
  color: inherit;
  font-weight: var(--font-weight-semibold);
  transition: var(--transition-all-short);

  &:hover {
    color: var(--color-fg-accent);
  }
}
