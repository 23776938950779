.wrapper {
	display: flex;
	gap: var(--spacing-lg);
	margin: 0 auto;
	max-width: 65rem;

	@media screen and (max-width: 55rem) {
		flex-direction: column;
		gap: var(--spacing-xl);
		max-width: calc(min(30rem, 100%));
	}
}

.sidebar {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
  flex-shrink: 0;
  font-size: var(--font-size-sm);
	gap: var(--spacing-md);
	max-width: 19rem;
	position: sticky;
	top: var(--spacing-lg);

	@media screen and (max-width: 55rem) {
		position: initial;
		max-width: 100%;
		width: 100%;
	}
}

.hacks {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-md);
	list-style-type: none;
	overflow: hidden;
	padding: 0;
}
