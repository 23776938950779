.badge {
	border-radius: var(--border-radius-md);
	display: inline-block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm);
  margin-left: auto;
  padding: 3px;
  pointer-events: none;

  &--working {
    background: var(--color-bg-success);
  	color: var(--color-fg-success);
  }

  &--deprecated {
  	background: var(--color-bg-danger);
    color: var(--color-fg-danger);
  }

  &--unknown {
  	background: var(--color-bg-warning);
    color: var(--color-fg-warning);
  }
}
