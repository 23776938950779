*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  accent-color: var(--color-fg-accent);
	background-color: var(--color-bg-primary);
  color: var(--color-fg-primary);
  height:     100%;
  font-size:  16px;
}

body {
  margin: 0;
  padding: var(--spacing-lg);
  width: 100%;

  @media only screen and (max-width: 55rem) {
  	padding: var(--spacing-lg) var(--spacing-md);
  }
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}
