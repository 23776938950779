:root {
  --color-brand: #389BFF;

  --color-fg-primary: #2E3538;
  --color-fg-secondary: #606F76;
  --color-fg-accent: var(--color-brand);
  --color-fg-success: #0C7942;
  --color-fg-warning: #A5610E;
  --color-fg-danger: #C33022;

  --color-bg-primary: #fff;
  --color-bg-secondary: #F1F2F3;
  --color-bg-success: #E1F1E9;
  --color-bg-warning: #FDF6EC;
  --color-bg-danger: #FFECEA;

  --color-border-primary: #C7CED1;
  --color-border-secondary: var(--color-bg-secondary);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-brand: #389bff;

    --color-fg-primary: #F1F3F3;
    --color-fg-secondary: #A5B0B6;
    --color-fg-accent: var(--color-brand);
    --color-fg-success: #2EB873;
    --color-fg-warning: #D49A54;
    --color-fg-danger: #DA766C;

    --color-bg-primary: #161B1D;
    --color-bg-secondary: #21282C;
    --color-bg-success: #1D3F2E;
    --color-bg-warning: #452B07;
    --color-bg-danger: #4D0700;

    --color-border-primary: #415058;
  }
}
